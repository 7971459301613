import React from 'react';
import classes from './Navbar.module.css'
import logo from '../../../media/logo.svg'
import darkLogo from '../../../media/darklogo.svg'
import NavbarItems from "./NavbarItems";
import {HiChevronDoubleDown, HiChevronDoubleUp} from 'react-icons/hi'
import SiteSelector from "./SiteSelector";
import {animated, easings, useSpring, useSpringRef, useTransition} from "react-spring";
import {Link} from "react-router-dom";

const Navbar = ({navLogoRef}) => {
    const [siteSelectorOpen, setSiteSelectorOpen] = React.useState(false);
    const [disableSelectorToggle, setDisableSelectorToggle] = React.useState(false);

    function handleToggleSiteSelector() {
        if (disableSelectorToggle)
            return;
        setSiteSelectorOpen(!siteSelectorOpen);
    }

    React.useEffect(() => {
        function handleScroll() {
            setSiteSelectorOpen(false);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll)
    }, []);

    const AnimatedHiChevronDoubleDown = animated(HiChevronDoubleDown);
    const AnimatedHiChevronDoubleUp = animated(HiChevronDoubleUp);
    const AnimatedSiteSelector = animated(SiteSelector);

    const transition = useTransition(siteSelectorOpen, {
        from: {opacity: 0, position: 'absolute'},
        enter: {opacity: 1},
        leave: {opacity: 0},
        config: {
            duration: 500,
            easing: easings.easeOutCubic
        },
        onStart: () => {
            setDisableSelectorToggle(true);
            navRef.start();
        }
    });

    const navRef = useSpringRef();
    const navSpring = useSpring({
        ref: navRef,
        from: {backgroundColor: siteSelectorOpen ? 'black' : 'white'},
        to: {backgroundColor: siteSelectorOpen ? 'white' : 'black'},
        config: {
            duration: 500,
            easing: easings.easeOutCubic
        },
        onRest: () => {
            setDisableSelectorToggle(false);
        }
    });

    return (
        <>
            <div className={classes.dummy}/>
            <header className={classes.header}>
                <animated.div
                    className={classes.logoContainer}
                    style={navSpring}
                >
                    <div className={classes.innerLogoContainer}>
                        <div className={classes.swapContainer}>
                            <HiChevronDoubleDown
                                className={classes.dropdown}
                                style={{opacity: 0}}
                            />
                            {transition((style, item) => {
                                return item ?
                                    <AnimatedHiChevronDoubleUp
                                        className={[classes.dropdown, classes.close].join(' ')}
                                        onClick={handleToggleSiteSelector}
                                        style={style}
                                    />
                                    :
                                    <AnimatedHiChevronDoubleDown
                                        className={classes.dropdown}
                                        onClick={handleToggleSiteSelector}
                                        style={style}
                                    />
                            })}
                        </div>
                        <Link to='/'>
                            <div className={classes.swapContainer}
                                 ref={navLogoRef}>
                                <animated.img
                                    src={darkLogo}
                                    alt="XSC logo"
                                    style={{opacity: 0}}
                                />
                                {transition((style, item) => {
                                    return item ?
                                        <animated.img
                                            src={darkLogo}
                                            alt="XSC logo"
                                            style={style}
                                        />
                                        :
                                        <animated.img
                                            src={logo}
                                            alt="XSC logo"
                                            style={style}
                                        />
                                })}
                            </div>
                        </Link>
                    </div>
                    {transition((style, item) => {
                        return item ?
                            <AnimatedSiteSelector style={style}/> : '';
                    })}
                </animated.div>
                <NavbarItems/>
            </header>
        </>
    );
};

export default Navbar;

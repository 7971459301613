import React from 'react';
import {Link} from 'react-router-dom';

const NavbarItems = () => {
    return (
        <ul>
            <li><Link to='/solutions'>Solutions</Link></li>
            <li><Link to='/clients'>Clients</Link></li>
            <li><Link to='/contact'>Contact Us</Link></li>
        </ul>
    );
};

export default NavbarItems;

import React from 'react';
import Intro from "../ui/sections/Intro";
import TextPlusSprite from "../ui/sections/TextPlusSprite";

import webSprite from '../sprites/websprite.svg';
import softwareSprite from '../sprites/softwaresprite.svg';
import marketingSprite from '../sprites/marketingsprite.svg';
import caseSprite from '../sprites/casesprite.svg';
import otherSprite from '../sprites/othersprite.svg';
import {Link} from "react-router-dom";

const Solutions = () => {
    return (
        <>
            <Intro text="Our Solutions"/>
            <TextPlusSprite sprite={softwareSprite} title="Bespoke Software" side="right">
                <p>XSC works with companies with unique problems within their organisation, creating a tailored product
                    that effectively solves these problems.</p>
                <p>Examples of our work include: blending automation with human tasks, saving valuable time;
                    consolidating and enabling streamlined access to unique data.</p>
                <p><Link to={"/contact"}>Get in touch</Link> today to discuss your needs.</p>
            </TextPlusSprite>
            <TextPlusSprite sprite={marketingSprite} title="Digital Marketing Strategy" side="left">
                <p>XSC offers digital marketing services; specifically, we take the weight off your shoulders and
                    deliver results with our performance focused approach.</p>
                <p><Link to={"/contact"}>Get in touch</Link> and let XSC bring you your customers.</p>
            </TextPlusSprite>
            <TextPlusSprite sprite={webSprite} title="Web Development" side="right">
                <p>We develop unique web products that are often unachievable with no-code solutions currently on the
                    market.</p>
                <p>XSC works closely with our clients to produce a detailed specification that is closely followed by us
                    to perfectly fulfill your needs.</p>
                <p>We employ the principles of Extreme Programming (XP) & Agile Development with many of our clients to
                    iteratively make refinements and receive feedback from them until the design fully meets the clients
                    demands.</p>
                <p><Link to={"/contact"}>Get in touch</Link> to give your business the website it deserves.</p>
            </TextPlusSprite>
            <TextPlusSprite sprite={caseSprite} title="Audiovisual Rental" side="left">
                <p>XSC holds a rental stock of professional lighting and audio equipment.</p>
                <p>Where our equipment is not sufficient for your needs we have access to a network of companies with
                    more hire stock.</p>
                <p>XSC can provide ISCVE (Institute of Sound, Visual & Communication) accredited engineers rather than
                    just dry hire equipment if you require.</p>
                <p>Where off the shelf equipment does not meet your needs, we may consider design and manufacture of a
                    solution.</p>
                <p><Link to={"/contact"}>Get in touch</Link> no matter the size of your production to discuss a
                    solution.</p>
            </TextPlusSprite>
            <TextPlusSprite sprite={otherSprite} title="Something Else?" side="left">
                <p>XSC has experience in a variety of industries so do not hesitate to <Link to={"/contact"}>Get in
                    touch</Link> about your needs if they are not listed above.</p>
                <p>If we cannot meet your needs, we may be able to refer you to another trusted company.</p>
            </TextPlusSprite>
        </>
    );
};

export default Solutions;
import React from 'react';
import MasterMainSite from "../ui/MasterMainSite";
import {Route, Routes} from "react-router-dom";
import Home from "../pages/Home";
import './MainSiteRouter.css';
import Solutions from "../pages/Solutions";
import Clients from "../pages/Clients";
import Contact from "../pages/Contact";
import Privacy from "../pages/Privacy";

const MainSiteRouter = () => {
    return (
        <MasterMainSite>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/solutions' element={<Solutions/>}/>
                <Route path='/clients' element={<Clients/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/privacy' element={<Privacy/>}/>
            </Routes>
        </MasterMainSite>
    );
};

export default MainSiteRouter;

import classes from './LogoSplash.module.css';
import {useSpring, animated, easings} from 'react-spring';
import logo from '../../media/darklogo.svg'
import React from 'react';

const LogoSplash = ({onAnimationPlayed, animationFading, setAnimationFading, navLogoMargin}) => {

    const splashEasing = easings.easeOutCubic;

    const holdTime = 600;
    const playTime = 1200;
    const splashText = 'Inspiring Solutions.';
    const logoSplashSpring = useSpring({
        from: {clipPath: 'polygon(0 0, 0% 0, -32.5% 100%, -32.5% 100%)'},
        to: [{clipPath: 'polygon(0 0, 132.5% 0, 100% 100%, -32.5% 100%)'},
            {clipPath: 'polygon(0 0, 0% 0, -32.5% 100%, -32.5% 100%)'}],
        config: {
            duration: playTime,
            easing: splashEasing
        }
    });
    const secondSplashSpring = useSpring({
        from: {clipPath: 'polygon(132.5% 0, 132.5% 0, 100% 100%, 100% 100%)', y: '50%'},
        to: [{clipPath: 'polygon(0% 0, 132.5% 0, 100% 100%, -32.5% 100%)', y: '0%'},
            {clipPath: 'polygon(132.5% 0, 132.5% 0, 100% 100%, 100% 100%)', y: '0%'}],
        delay: playTime + holdTime,
        config: {
            duration: playTime,
            easing: splashEasing
        }
    });
    const thirdSplashSpring = useSpring({
        from: {clipPath: 'polygon(0 0, 0% 0, -32.5% 100%, -32.5% 100%)'},
        to: {clipPath: 'polygon(0 0, 132.5% 0, 100% 100%, -32.5% 100%)'},
        delay: 1.75 * playTime + holdTime,
        config: {
            duration: playTime * .75,
            easing: splashEasing
        },
    });
    const bgSpring = useSpring({
        from: {background: 'rgba(255,255,255,1)'},
        to: {background: 'rgba(255,255,255,0)'},
        delay: 2 * playTime + holdTime,
        config: {
            duration: playTime * 1.25,
            easing: splashEasing
        },
        onRest: () => {
            onAnimationPlayed();
        },
        onStart: () => {
            setAnimationFading(true);
        }
    });
    const navSpring = useSpring({
        from: {clipPath: 'polygon(132.5% 0, 132.5% 0, 100% 100%, 100% 100%)'},
        to: [{clipPath: 'polygon(0% 0, 132.5% 0, 100% 100%, -32.5% 100%)'},
            {clipPath: 'polygon(0 0, 0% 0, -32.5% 100%, -32.5% 100%)'}],
        delay: playTime + holdTime,
        config: {
            duration: playTime,
            easing: easings.easeOutQuint
        },
    });

    return (
        <>
            <animated.div style={bgSpring} className={classes.coverContainer}>
                <animated.div
                    className={classes.navCover}
                    style={{marginLeft: navLogoMargin - 32}}
                >
                    <animated.img className={classes.imageCover} style={navSpring} src={logo} alt="XSC Logo"/>
                </animated.div>
            </animated.div>
            {animationFading ? '' :
                <animated.div className={classes.container}>
                    <animated.img
                        className={classes.logoSplash}
                        src={logo}
                        alt='XSC Logo'
                        style={logoSplashSpring}
                    />
                    <animated.div
                        className={classes.secondSplash}
                        style={secondSplashSpring}
                    >
                        <img className={classes.logoSplash} src={logo} alt='XSC Logo' style={{opacity: 0}}/>
                        <h2>{splashText}</h2>
                    </animated.div>
                    <animated.div
                        className={classes.thirdSplash}
                        style={thirdSplashSpring}
                    >
                        <img className={classes.logoSplash} src={logo} alt='XSC Logo' style={{opacity: 0}}/>
                        <h2>{splashText}</h2>
                    </animated.div>
                </animated.div>
            }
        </>
    );
};

export default LogoSplash;

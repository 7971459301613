import React from 'react';
import mapSprite from '../sprites/mapsprite.svg';
import whoSprite from '../sprites/whosprite.svg';
import TextPlusSprite from "../ui/sections/TextPlusSprite";
import Intro from "../ui/sections/Intro";
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <>
            <Intro text="About XSC"/>
            <TextPlusSprite sprite={whoSprite} title="Who Are We?" side="right">
                <p>XSC is a consultancy specialising in software development, digital marketing and audiovisual services.</p>
                <p>For more information about what we do, see <Link to={"/solutions"}>our Solutions.</Link></p>
                <p>To read about our work, see <Link to={"/clients"}>our Clients.</Link></p>
            </TextPlusSprite>
            <TextPlusSprite sprite={mapSprite} title="Where?" side="left">
                <p>We provide software solutions across the globe.</p>
                <p>Our UK headquarters are situated in Royal Tunbridge Wells.</p>
                <p>We also operate in the London and Cambridge areas.</p>
                <p>Where required we can come to you, wherever in the country that may be.</p>
            </TextPlusSprite>
        </>
    )
};

export default Home;

import React from 'react';
import Cookies from 'universal-cookie';
import MobileNavbar from './navigation/MobileNavbar'
import Navbar from './navigation/Navbar'
import LogoSplash from "../startup/LogoSplash";
import Footer from "./Footer";
import ScrollEncourage from "./ScrollEncourage";

const MasterMainSite = ({children}) => {
    const threshold = 960;

    const cookies = new Cookies();
    const [isMobile, setIsMobile] = React.useState(false);
    const [animationPlayed, setAnimationPlayed] = React.useState(cookies.get('animationPlayed'));
    const [animationFading, setAnimationFading] = React.useState(false);
    const [navLogoMargin, setNavLogoMargin] = React.useState(0);

    React.useEffect(() => {
        function handleResize() {
            setIsMobile(Math.min(window.screen.width, window.innerWidth) <= threshold);
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    function animationPlayedHandler() {
        setAnimationPlayed(true);
        cookies.set('animationPlayed', true, {maxAge: 300});
    }

    const navLogo = React.useCallback(node => {
        if (node !== null) {
            setNavLogoMargin(node.getBoundingClientRect().left);
        }
    }, []);

    return (
        <>
            <ScrollEncourage/>
            {isMobile ?
                <MobileNavbar navLogoRef={navLogo}/> :
                <Navbar navLogoRef={navLogo}/>
            }
            {animationPlayed ? '' :
                <LogoSplash
                    onAnimationPlayed={animationPlayedHandler}
                    animationFading={animationFading}
                    setAnimationFading={setAnimationFading}
                    navLogoMargin={navLogoMargin}
                ></LogoSplash>
            }
            <main style={{flex: 1}}>
                {animationFading || animationPlayed ? (
                    children
                ) : ''
                }
            </main>
            <Footer/>
        </>
    );
};

export default MasterMainSite;

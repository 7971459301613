import React from 'react';
import classes from './Footer.module.css'
import {Link} from "react-router-dom";

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <>
            <hr className={classes.divider}/>
            <footer className={classes.footer}>
                <div className={classes.legal}>
                    <p>Copyright &copy; {year} XSC GROUP LTD. </p>
                    <p>20 Broadwater Down, Tunbridge Wells, TN2 5NR</p>
                    <p>Company No. 13242345. All rights reserved. <Link to={"privacy"}>Privacy Policy.</Link></p>
                </div>
            </footer>
        </>
    );
};

export default Footer;

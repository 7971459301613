import React from 'react';
import SpacyTitle from "../ui/sections/SpacyTitle";
import Intro from "../ui/sections/Intro";
import ThumbRow from "../ui/sections/ThumbRow";
import Thumb from "../ui/sections/Thumb";
import epicColsLogo from "../../media/epiccolslogo.svg";
import roonReadyLogo from "../../media/rreadylogo.svg";

const Clients = () => {
    return (
        <>
            <Intro text="Our Clients"/>
            <SpacyTitle selfMargin>Proud To Work With</SpacyTitle>
            <ThumbRow>
                <Thumb image={epicColsLogo}/>
                <Thumb image={roonReadyLogo}/>
            </ThumbRow>
        </>
    );
};

export default Clients;
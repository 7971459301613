import MainSiteRouter from "./components/routing/MainSiteRouter";
import AppsRouter from "./components/routing/AppsRouter";
import MarketingRouter from "./components/routing/MarketingRouter";

import './App.css'

function App() {
    const site = window.location.host.split('.')[0];

    switch (site) {
        case 'marketing':
            return (<MarketingRouter/>);
        case 'apps':
            return (<AppsRouter/>);
        default:
            return (<MainSiteRouter/>);
    }
}

export default App;

import React from 'react';
import classes from './MobileNavbar.module.css';
import Navbar from "./Navbar";

const MobileNavbar = ({navLogoRef}) => {
    return (
        <>
            <Navbar className={classes.navbar} navLogoRef={navLogoRef}/>
        </>
    );
};

export default MobileNavbar;

import React from 'react';

import classes from "./Intro.module.css";

const Intro = ({text}) => {
    return (
        <div className={classes.container}>
            <h5>{text}</h5>
        </div>
    );
};

export default Intro;
import React from 'react';
import classes from './SiteSelector.module.css';

const SiteSelector = () => {
    const prot = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://' : 'https://'
    const host = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'localhost:3000' : 'xsc.co.uk'

    return (
        <div className={classes.siteSelector}>
            <ul>
                <li className={classes.siteSelectorOption}><a href={prot + host}>Main Site</a></li>
                {/*<li className={classes.siteSelectorOption}><a href={prot + 'apps.' + host}>Apps</a></li>*/}
                {/*<li className={classes.siteSelectorOption}><a href={prot + 'marketing.' + host}>Marketing</a></li>*/}
            </ul>
        </div>
    );
};

export default SiteSelector;

import React from 'react';

import classes from './ScrollEncourage.module.css';

const ScrollEncourage = () => {

    const [show, setShow] = React.useState(document.documentElement.scrollTop
        < document.documentElement.scrollHeight - 1.5 * window.outerHeight);

    React.useEffect(() => {
        let waiting = false;

        function handleScroll() {
            let shouldDisplay = document.documentElement.scrollTop
                < document.documentElement.scrollHeight - 1.5 * window.outerHeight;
            if (shouldDisplay) {
                if (waiting) return;

                setShow(true);

                waiting = true;
                setShow(false);
                setTimeout(() => {
                    setShow(document.documentElement.scrollTop
                        < document.documentElement.scrollHeight - 1.5 * window.outerHeight);
                    waiting = false;
                }, 7500);
            } else {
                setShow(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll)
    }, []);

    return <div
        className={classes.scrollEncourage}
        style={{opacity: show ? 100 : 0}}
    />;
};

export default ScrollEncourage;
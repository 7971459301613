import React from 'react';
import classes from "./SpacyTitle.module.css";

const SpacyTitle = ({children, selfMargin}) => {
    return selfMargin ?
        <div className={classes.selfMarginContainer}>
            <h1 className={classes.title}>
                {children}
            </h1>
        </div> :
        <>
            <h1 className={classes.title}>
                {children}
            </h1>
        </>;
};

export default SpacyTitle;
import React from 'react';
import {Route, Routes} from "react-router-dom";
import MarketingHome from "../marketing/pages/MarketingHome";

const MarketingRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<MarketingHome/>}/>
        </Routes>
    );
};

export default MarketingRouter;

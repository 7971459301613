import React from 'react';
import {Link} from "react-router-dom";

const AppsHome = () => {
    return (
        <div>
            <h2>Apps:</h2>
            <ul>
                <li><Link to='/showtime'>Showtime</Link></li>
            </ul>
        </div>
    );
};

export default AppsHome;

import React from 'react';
import {Route, Routes} from "react-router-dom";
import AppsHome from "../apps/pages/AppsHome";

const AppsRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<AppsHome/>}/>

        </Routes>
    );
};

export default AppsRouter;

import React from 'react';
import ContactCard from "../ui/sections/ContactCard";

const Contact = () => {
    return (
        <div>
            <ContactCard/>
        </div>
    );
};

export default Contact;
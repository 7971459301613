import React from 'react';

import classes from "./ContactCard.module.css";
import {HiOutlineMail, HiOutlinePhone} from 'react-icons/hi'


const ContactCard = () => {
    return (
        <>
            <a href='mailto:info@xsc.co.uk' className={classes.container}>
                <div className={classes.iconBox}>
                    <HiOutlineMail/>
                </div>
                <div className={classes.vert}>
                    <span><strong>EMAIL US</strong> info@xsc.co.uk</span>
                </div>
            </a>
            {/*<br/>*/}
            {/*<a href='tel:00441892570017' className={classes.container}>*/}
            {/*    <div className={classes.iconBox}>*/}
            {/*        <HiOutlinePhone/>*/}
            {/*    </div>*/}
            {/*    <div className={classes.vert}>*/}
            {/*        <span><strong>TUN WELLS, UK</strong> +44 1892 570017</span>*/}
            {/*        /!*<span><strong>MIAMI, FL</strong> +1 (786) 876 8442</span>*!/*/}
            {/*    </div>*/}
            {/*</a>*/}
        </>
    );
};

export default ContactCard;
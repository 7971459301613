import React from 'react';
import classes from './TextPlusSprite.module.css';
import SpacyTitle from "./SpacyTitle";

const TextPlusSprite = ({sprite, title, children, side}) => {

    return (
        <div className={classes.container} style={side === "right" ? {flexDirection: "row-reverse"} : {}}>
            <object className={classes.sprite} type="image/svg+xml" data={sprite}>Sprite {sprite}</object>
            <div className={classes.textContainer} style={side === "right" ? {textAlign: "right"} : {}}>
                <SpacyTitle>{title}</SpacyTitle>
                {children}
            </div>
        </div>
    );
};

export default TextPlusSprite;
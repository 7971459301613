import React from 'react';
import classes from "./Privacy.module.css";
import Intro from "../ui/sections/Intro";

const Privacy = () => {
    return (
        <div className={classes.parent}>
            <div className={classes.policy}>
                <Intro text="Privacy Policy"/>

                <p>This privacy policy applies between you, the User of this Website, and <span
                    className="boiler-field">XSC Group Ltd.</span>, the owner and provider of this
                    Website. <span className="boiler-field">XSC Group Ltd.</span> takes the privacy of
                    your information very seriously. This privacy policy applies to our use of any and all Data
                    collected by us or provided by you in relation to your use of the Website.</p>


                <p>&nbsp;</p>

                <p><b>Please read this privacy policy carefully</b>.</p>

                <h2>Definitions and interpretation</h2>

                <ol className="clauses firstList">
                    <li>In this privacy policy, the following definitions are used:
                        <table className="definitions">
                            <tbody>
                            <tr>
                                <td><b>Data</b></td>
                                <td>collectively all information that you submit to <span
                                    className="boiler-field">XSC Group Ltd.</span> via the Website.
                                    This definition incorporates, where applicable, the definitions provided in the Data
                                    Protection Laws;
                                </td>
                            </tr>

                            <tr>
                                <td><b>Cookies</b></td>
                                <td>a small text file placed on your computer by this Website when you
                                    visit certain parts of the Website and/or when you use certain features of the
                                    Website. Details of the cookies used by this Website are set out in the clause below
                                    (<b>Cookies</b>);
                                </td>
                            </tr>
                            <tr>
                                <td><b>Data Protection Laws</b></td>
                                <td>
                                    <p><span>any applicable law relating to the processing of personal Data, including but not limited to the&nbsp;GDPR, and any national implementing and supplementary laws, regulations and secondary legislation;</span>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td><b>GDPR</b></td>
                                <td>
                                    <p><span>the UK General Data Protection Regulation;</span>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td><b><span
                                    className="boiler-field">XSC Group Ltd.</span>, <span>&nbsp;</span><br/>
                                    <span>&nbsp;</span>we </b> or <b>us</b></td>
                                <td> <span
                                > <span
                                    className="boiler-field">XSC Group Ltd.</span>, a company incorporated in <span>England and Wales</span> with registered number <span

                                    className="boiler-field">13242345</span> whose registered office is at <span

                                    className="boiler-field">20 Broadwater Down</span>,&nbsp;<span
                                ><span

                                    className="boiler-field">Tunbridge Wells</span>, </span> <span
                                > <span
                                    className="boiler-field">Kent</span>, </span> <span
                                    className="boiler-field">TN2 5NR</span>; </span></td>
                            </tr>

                            <tr>
                                <td><b>UK and EU Cookie Law</b></td>
                                <td>the Privacy and Electronic Communications (EC Directive)
                                    Regulations 2003 as amended by the Privacy and Electronic Communications (EC
                                    Directive) (Amendment) Regulations 2011 &amp; the Privacy and Electronic
                                    Communications (EC Directive) (Amendment) Regulations 2018;
                                </td>
                            </tr>
                            <tr>
                                <td><b>User</b> or <b>you</b></td>
                                <td>any third party that accesses the Website and is not either (i)
                                    employed by <span
                                        className="boiler-field">XSC Group Ltd.</span> and acting in the
                                    course of their employment or (ii) engaged as a consultant or otherwise providing
                                    services to <span
                                        className="boiler-field">XSC Group Ltd.</span> and accessing the
                                    Website in connection with the provision of such services; and
                                </td>
                            </tr>
                            <tr>
                                <td><b>Website</b></td>
                                <td>the website that you are currently using, <span
                                    className="boiler-field">https://xsc.co.uk</span>,
                                    and any sub-domains of this site unless expressly excluded by their own terms and
                                    conditions.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>In this privacy policy, unless the context requires a different
                        interpretation:
                        <ol>
                            <li>the singular includes the plural and vice versa;</li>
                            <li>references to sub-clauses, clauses, schedules or appendices are to
                                sub-clauses, clauses, schedules or appendices of this privacy policy;
                            </li>
                            <li>a reference to a person includes firms, companies, government
                                entities, trusts and partnerships;
                            </li>
                            <li>"including" is understood to mean "including without limitation";</li>
                            <li>reference to any statutory provision includes any modification or
                                amendment of it;
                            </li>
                            <li>the headings and sub-headings do not form part of this privacy
                                policy.
                            </li>
                        </ol>
                    </li>
                </ol>

                <h2>Scope of this privacy policy</h2>

                <ol className="clauses">
                    <li>This privacy policy applies only to the actions of <span
                        className="boiler-field">XSC Group Ltd.</span> and
                        Users with respect to this Website. It does not extend to any websites that can be accessed from
                        this Website including, but not limited to, any links we may provide to social media websites.
                    </li>
                    <li>For purposes of the applicable Data Protection Laws, <span
                        className="boiler-field">XSC Group Ltd.</span> is
                        the "data controller". This means that <span
                            className="boiler-field">XSC Group Ltd.</span> determines
                        the purposes for which, and the manner in which, your Data is processed.
                    </li>
                </ol>


                <div>
                    <h2>Data collected</h2>

                    <ol className="clauses">
                        <li>We may collect the following Data, which includes personal Data, from you:
                            <ol>

                                <li>name;</li>


                                <li>job title;</li>


                                <li>contact Information
                                    such as email addresses and telephone numbers;
                                </li>


                                <li>IP address
                                    (automatically collected);
                                </li>

                                <li>web browser type
                                    and version (automatically collected);
                                </li>

                                <li>operating system
                                    (automatically collected);
                                </li>
                            </ol>

                            <p>in each case, in accordance with this privacy policy.</p>
                        </li>
                    </ol>
                </div>

                <h2>How we collect Data</h2>

                <ol className="clauses">
                    <li>We collect Data in the following ways:
                        <ol>
                            <li>data is given to us by you<span>;</span>
                            </li>

                            <li>data is received from
                                other sources; and
                            </li>
                            <li>data is collected automatically.</li>
                        </ol>
                    </li>
                </ol>

                <h2>Data that is given to us by you</h2>

                <ol className="clauses">
                    <li><span>XSC Group Ltd.</span> will
                        collect your Data in a number of ways, for example:

                        <ol>
                            <li>when you contact us through the Website, by telephone, post, e-mail or
                                through any other means;
                            </li>


                            <li>when you elect to
                                receive marketing communications from us;
                            </li>

                            <li>when you use our
                                services;
                            </li>
                        </ol>

                        <p>in each case, in accordance with this privacy policy.</p>
                    </li>
                </ol>


                <div>
                    <h2>Data that is received from third parties</h2>

                    <ol className="clauses">
                        <li><span>XSC Group Ltd.</span> will receive Data
                            about you from the following third parties:

                            <ol>
                                <li><span>Facebook Inc.</span><span>; </span></li>
                                <li><span>Alphabet Inc.</span><span>.</span></li>
                            </ol>
                        </li>
                    </ol>
                </div>


                <h2>Data that is collected automatically</h2>

                <ol className="clauses">
                    <li>To the extent that you access the Website, we will collect your Data
                        automatically, for example:
                        <ol>
                            <li>we automatically collect some information about your visit to the
                                Website. This information helps us to make improvements to Website content and
                                navigation, and includes your IP address, the date, times and frequency with which you
                                access the Website and the way you use and interact with its content.
                            </li>

                            <li>we will collect your
                                Data automatically via cookies, in line with the cookie settings on your browser. For
                                more information about cookies, and how we use them on the Website, see the section
                                below, headed "Cookies".
                            </li>
                        </ol>
                    </li>
                </ol>


                <div>
                    <h2>Our use of Data</h2>

                    <ol className="clauses">
                        <li>Any or all of the above Data may be required by us from time to time in
                            order to provide you with the best possible service and experience when using our Website.
                            Specifically, Data may be used by us for the following reasons:
                            <ol>

                                <li>internal record
                                    keeping;
                                </li>

                                <li>improvement of our
                                    products / services;
                                </li>

                                <li>transmission by
                                    email of marketing materials that may be of interest to you;
                                </li>


                            </ol>

                            <p>in each case, in accordance with this privacy policy.</p>
                        </li>
                        <li>We may use your Data for the above purposes if we deem it necessary to do
                            so for our legitimate interests. If you are not satisfied with this, you have the right to
                            object in certain circumstances (see the section headed "Your rights" below).
                        </li>

                        <li>For the delivery of direct
                            marketing to you via e-mail, we'll need your consent, whether via an opt-in or soft-opt-in:
                            <ol>
                                <li>soft opt-in consent is a specific type of consent which applies
                                    when you have previously engaged with us (for example, you contact us to ask us for
                                    more details about a particular product/service, and we are marketing similar
                                    products/services). Under "soft opt-in" consent, we will take your consent as given
                                    unless you opt-out.
                                </li>
                                <li>for other types of e-marketing, we are required to obtain your
                                    explicit consent; that is, you need to take positive and affirmative action when
                                    consenting by, for example, checking a tick box that we'll provide.
                                </li>
                                <li>if you are not satisfied with our approach to marketing, you have
                                    the right to withdraw consent at any time. To find out how to withdraw your consent,
                                    see the section headed "Your rights" below.
                                </li>
                            </ol>
                        </li>


                        <li>We may use your Data to
                            show you <span className="boiler-field">XSC Group Ltd.</span> adverts and
                            other content on other websites.
                        </li>
                    </ol>
                </div>


                <div>
                    <h2>Who we share Data with</h2>

                    <ol className="clauses">
                        <li>We may share your Data with the following groups of people for the
                            following reasons:
                            <ol>


                                <li>our employees,
                                    agents and/or professional advisors - <span
                                        className="boiler-field">to receive information on our products and services</span>;
                                </li>

                                <li>third party service
                                    providers who provide services to us which require the processing of personal data
                                    - <span
                                        className="boiler-field">to aid the function of this website</span>;
                                </li>


                            </ol>

                            <p>in each case, in accordance with this privacy policy.</p>
                        </li>
                    </ol>
                </div>

                <h2>Keeping Data secure</h2>

                <ol className="clauses">
                    <li>We will use technical and organisational measures to safeguard your Data, for
                        example:
                        <ol>
                            <li>access to your account is controlled by a password and a user name
                                that is unique to you.
                            </li>
                            <li>we store your Data on secure servers.</li>

                        </ol>
                    </li>


                    <li>Technical and organisational measures include measures to deal with any
                        suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data,
                        please let us know immediately by contacting us via this e-mail address: <span
                            className="boiler-field">info@xsc.co.uk</span>.
                    </li>
                    <li>If you want detailed information from Get Safe Online on how to protect your
                        information and your computers and devices against fraud, identity theft, viruses and many other
                        online problems, please visit www.getsafeonline.org. Get Safe Online is supported by HM
                        Government and leading businesses.
                    </li>
                </ol>

                <h2>Data retention</h2>

                <ol className="clauses">
                    <li>Unless a longer retention period is required or permitted by law, we will only
                        hold your Data on our systems for the period necessary to fulfil the purposes outlined in this
                        privacy policy or until you request that the Data be deleted.
                    </li>
                    <li>Even if we delete your Data, it may persist on backup or archival media for
                        legal, tax or regulatory purposes.
                    </li>
                </ol>

                <h2>Your rights</h2>

                <ol className="clauses">
                    <li>You have the following rights in relation to your Data:
                        <ol>
                            <li><b>Right to access</b> - the right to request (i)
                                copies of the information we hold about you at any time, or (ii) that we modify, update
                                or delete such information. If we provide you with access to the information we hold
                                about you, we will not charge you for this, unless your request is "manifestly unfounded
                                or excessive." Where we are legally permitted to do so, we may refuse your request. If
                                we refuse your request, we will tell you the reasons why.
                            </li>
                            <li><b>Right to correct</b> - the right to have your
                                Data rectified if it is inaccurate or incomplete.
                            </li>
                            <li><b>Right to erase</b> - the right to request that we
                                delete or remove your Data from our systems.
                            </li>
                            <li><b>Right to restrict our use of your Data</b> - the
                                right to "block" us from using your Data or limit the way in which we can use it.
                            </li>
                            <li><b>Right to data portability</b> - the right to
                                request that we move, copy or transfer your Data.
                            </li>
                            <li><b>Right to object</b> - the right to object to our
                                use of your Data including where we use it for our legitimate interests.
                            </li>
                        </ol>
                    </li>
                    <li>To make enquiries, exercise any of your rights set out above, or withdraw your
                        consent to the processing of your Data (where consent is our legal basis for processing your
                        Data), please contact us via this e-mail address: <span
                            className="boiler-field">info@xsc.co.uk</span>.
                    </li>
                    <li>If you are not satisfied with the way a complaint you make in relation to your
                        Data is handled by us, you may be able to refer your complaint to the relevant data protection
                        authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's contact
                        details can be found on their website at https://ico.org.uk/.
                    </li>
                    <li>It is important that the Data we hold about you is accurate and current.
                        Please keep us informed if your Data changes during the period for which we hold it.
                    </li>
                </ol>


                <h2>Links to other websites</h2>

                <ol className="clauses">
                    <li>This Website may, from time to time, provide links to other websites. We have
                        no control over such websites and are not responsible for the content of these websites. This
                        privacy policy does not extend to your use of such websites. You are advised to read the privacy
                        policy or statement of other websites prior to using them.
                    </li>
                </ol>

                <h2>Changes of business ownership and control</h2>

                <ol className="clauses">
                    <li><span className="boiler-field">XSC Group Ltd.</span> may,
                        from time to time, expand or reduce our business and this may involve the sale and/or the
                        transfer of control of all or part of <span className="boiler-field">XSC Group Ltd.</span>.
                        Data provided by Users will, where it is relevant to any part of our business so transferred, be
                        transferred along with that part and the new owner or newly controlling party will, under the
                        terms of this privacy policy, be permitted to use the Data for the purposes for which it was
                        originally supplied to us.
                    </li>
                    <li>We may also disclose Data to a prospective purchaser of our business or any
                        part of it.
                    </li>
                    <li>In the above instances, we will take steps with the aim of ensuring your
                        privacy is protected.
                    </li>
                </ol>


                <div>
                    <h2>Cookies</h2>

                    <ol className="clauses">
                        <li>This Website may place and access certain Cookies on your
                            computer. <span> <span
                                className="boiler-field">XSC Group Ltd.</span> uses Cookies to improve your experience of using the Website and to improve our range of products and services. </span>
                            <span className="boiler-field">XSC Group Ltd.</span> has carefully chosen
                            these Cookies and has taken steps to ensure that your privacy is protected and respected at
                            all times.
                        </li>
                        <li>All Cookies used by this Website are used in accordance with current UK
                            and EU Cookie Law.
                        </li>
                        <li>Before the Website places Cookies on your computer, you will be presented
                            with a message bar requesting your consent to set those Cookies. By giving your consent to
                            the placing of Cookies, you are enabling <span
                                className="boiler-field">XSC Group Ltd.</span> to
                            provide a better experience and service to you. You may, if you wish, deny consent to the
                            placing of Cookies; however certain features of the Website may not function fully or as
                            intended.
                        </li>
                        <li>This Website may place the following Cookies:
                            <p>&nbsp;</p>

                            <table className="withBorders">
                                <tbody>
                                <tr>
                                    <td align="center" valign="center" width="50%"><b
                                    >Type of Cookie</b></td>
                                    <td align="center" valign="center" width="50%"><b
                                    >Purpose</b></td>
                                </tr>
                                <tr>
                                    <td align="left" valign="top">Strictly necessary cookies</td>
                                    <td align="left" valign="top">These are cookies that are required
                                        for the operation of our website. They include, for example, cookies that enable
                                        you to log into secure areas of our website, use a shopping cart or make use of
                                        e-billing services.
                                    </td>
                                </tr>

                                <tr>
                                    <td align="left" valign="top">Analytical/performance cookies</td>
                                    <td align="left" valign="top">They allow us to recognise and count
                                        the number of visitors and to see how visitors move around our website when they
                                        are using it. This helps us to improve the way our website works, for example,
                                        by ensuring that users are finding what they are looking for easily.
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left" valign="top">Functionality cookies</td>
                                    <td align="left" valign="top">These are used to recognise you when
                                        you return to our website. This enables us to personalise our content for you,
                                        greet you by name and remember your preferences (for example, your choice of
                                        language or region). By using the Website, you agree to our placement of
                                        functionality cookie.
                                    </td>
                                </tr>

                                <tr rl-text="59b462af-85bf-484e-90ee-efa9037f01fc">
                                    <td align="left" valign="top">Targeting cookies</td>
                                    <td align="left" valign="top">These cookies record your visit to
                                        our website, the pages you have visited and the links you have followed. We will
                                        use this information to make our website and the advertising displayed on it
                                        more relevant to your interests. We may also share this information with third
                                        parties for this purpose.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>You can find a list of Cookies that we use in the Cookies Schedule.</li>

                        <li>You can&nbsp;choose to enable or disable Cookies in your internet
                            browser. By default, most internet browsers accept Cookies but this can be changed. For
                            further details, please see the help menu in your internet browser. You can switch
                            off &nbsp;Cookies at any time, however, you may lose any information that enables you to
                            access the Website more quickly and efficiently.
                        </li>
                        <li>You can choose to delete Cookies at any time; however, you may lose any
                            information that enables you to access the Website more quickly and efficiently including,
                            but not limited to, personalisation settings.
                        </li>
                        <li>It is recommended that you ensure that your internet browser is up-to-date
                            and that you consult the help and guidance provided by the developer of your internet
                            browser if you are unsure about adjusting your privacy settings.
                        </li>
                        <li>For more information generally on cookies, including how to disable them,
                            please refer to aboutcookies.org. You will also find details on how to delete cookies from
                            your computer.
                        </li>
                    </ol>
                </div>

                <h2>General</h2>

                <ol className="clauses">
                    <li>You may not transfer any of your rights under this privacy policy to any other
                        person. We may transfer our rights under this privacy policy where we reasonably believe your
                        rights will not be affected.
                    </li>
                    <li>If any court or competent authority finds that any provision of this privacy
                        policy (or part of any provision) is invalid, illegal or unenforceable, that provision or
                        part-provision will, to the extent required, be deemed to be deleted, and the validity and
                        enforceability of the other provisions of this privacy policy will not be affected.
                    </li>
                    <li>Unless otherwise agreed, no delay, act or omission by a party in exercising
                        any right or remedy will be deemed a waiver of that, or any other, right or remedy.
                    </li>
                    <li>This Agreement will be governed by and interpreted according to the law
                        of <span>England and Wales</span>. All
                        disputes arising under the Agreement will be subject to the exclusive jurisdiction of
                        the <span>English and Welsh courts</span>.
                    </li>
                </ol>

                <h2>Changes to this privacy policy</h2>

                <ol className="clauses">
                    <li><span
                        className="boiler-field">XSC Group Ltd.</span> reserves the right to
                        change this privacy policy as we may deem necessary from time to time or as may be required by
                        law. Any changes will be immediately posted on the Website and you are deemed to have accepted
                        the terms of the privacy policy on your first use of the Website following the
                        alterations. <span>&nbsp;</span><br/>
                        <span>&nbsp;</span> <span>&nbsp;</span><br/>
                        <span>&nbsp;</span> You may contact <span
                            className="boiler-field">XSC Group Ltd.</span> by email at <span
                            className="boiler-field">info@xsc.co.uk</span>.
                    </li>
                </ol>

                <p>&nbsp;</p>

                <p>This privacy policy was last revised on <strong><span
                    className="boiler-field">05 December 2022</span></strong>.</p>

                <div>

                    <div>
                        <p>&nbsp;</p>

                        <div>
                            <div>&nbsp;</div>

                            <h2>Cookies</h2>

                            <p>Below is a list of the cookies that we use. We have tried to ensure
                                this is complete and up to date, but if you think that we have missed a cookie or there
                                is any discrepancy, please let us know.</p>

                            <p>&nbsp;</p>

                            <p><strong>Strictly necessary</strong></p>

                            <p>&nbsp;</p>

                            <p>We use the following strictly necessary cookies:</p>

                            <p>&nbsp;</p>

                            <table border="1" cellPadding="1" cellSpacing="1">
                                <tbody>
                                <tr>
                                    <td width="40%"><strong
                                    >Description</strong></td>
                                    <td><strong
                                    >Purpose</strong></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <p><span>Session cookie</span>
                                            </p>

                                            <p>&nbsp;<span></span></p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p><span>We use this session cookie to remember you and maintain your session whilst you are using our website.</span>
                                            </p>
                                            <p>&nbsp;<span></span></p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <div>
                                <div>
                                    <p>&nbsp;</p>
                                </div>
                            </div>

                            <p><strong>Functionality</strong></p>

                            <div>
                                <p>&nbsp;</p>

                                <p>We use the following functionality cookies:</p>

                                <p>&nbsp;</p>

                                <table border="1" cellPadding="1" cellSpacing="1">
                                    <tbody>
                                    <tr>
                                        <td width="30%"><strong
                                        >Description</strong></td>
                                        <td><strong
                                        >Purpose</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <p><span>Tracking cookie</span>
                                                </p>
                                                <p>&nbsp;<span></span></p>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <p><span>We use this cookie to monitor traffic to this website.</span>
                                                </p>
                                                <p>&nbsp;<span></span></p>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div>
                            <p>&nbsp;</p>

                            <p><strong>Analytical/performance</strong></p>

                            <div>
                                <p>&nbsp;</p>

                                <p>We use the following analytical/performance cookies:</p>

                                <p>&nbsp;</p>

                                <table border="1" cellPadding="1" cellSpacing="1">
                                    <tbody>
                                    <tr>
                                        <td width="30%"><strong
                                        >Description</strong></td>
                                        <td><strong
                                        >Purpose</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <p><span>Analytical cookie</span>
                                                </p>
                                                <p>&nbsp;<span></span></p>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <p>
                                                    <span>We use this cookie to help improve the user experience on this website.</span>
                                                </p>
                                                <p>&nbsp;<span></span></p>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p>&nbsp;</p>
                        </div>
                    </div>


                    <div>
                        <p>&nbsp;</p>

                        <p><strong>Targeting</strong></p>

                        <div>
                            <p>&nbsp;</p>

                            <p>We use the following targeting cookies:</p>

                            <p>&nbsp;</p>

                            <table border="1" cellPadding="1" cellSpacing="1">
                                <tbody>
                                <tr>
                                    <td width="30%"><strong
                                    >Description</strong></td>
                                    <td><strong
                                    >Purpose</strong></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <p><span>Targeting cookie</span>
                                            </p>
                                            <p>&nbsp;<span></span></p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p><span>We use this cookie to attribute traffic to this website to advertisement campaigns.</span>
                                            </p>
                                            <p>&nbsp;<span></span></p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <p>&nbsp;</p>
                        </div>
                    </div>

                    <div id="null">
                        <div hidden="" id="end">&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;